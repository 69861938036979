import React from "react"
import Hero from "../components/Hero"
import Layout from "../components/Layout"
import SEO from "../components/SEO"

export default function Home() {
  return (
    <Layout>
      <SEO title="Accueil" />
      <Hero />
    </Layout>
  )
}
